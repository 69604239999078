const SCRIPTS = {
    affirm: 'affirm',
    gtm: 'gtm',
    instana: 'instana'
}

const scriptsToLoad = {}
Object.values(SCRIPTS).forEach(key => scriptsToLoad[key] = true)

const getScriptsToLoad = (excludedScripts = []) => {
    const loadedScripts = { ...scriptsToLoad }

    excludedScripts.forEach(scriptName => loadedScripts[scriptName] = false)

    return loadedScripts
}

export { SCRIPTS, getScriptsToLoad }
