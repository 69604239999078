import isEmpty from 'lodash/isEmpty'
import { buildDataLayerExperiments } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/launch-darkly.utils'
import { buildVariantForEEC } from '@/utils/analytics'

export const getDataLayerData = (pageType, experiments, flagOverrides) => {
    const dataLayerExperiments = buildDataLayerExperiments(experiments, flagOverrides)

    return {
        event: 'pageview',
        pageType, 
        ...dataLayerExperiments
    }
}

/**
 * Validates that window object exists
 *
 * eventObject expects at the bare minimum event property with a name
 * can pass any other property along deemed necessary by Digital tools for the events metadata
 * @param {{ event: string }} eventObject
 */
export const pushDataLayerEvent = (eventObject) => {
    if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({...eventObject})
    }
}


export function pushAddToCartDataLayer (productstoAdd, cartItems, dimension = undefined) {
    try {
        if (isEmpty(productstoAdd)) {
            return () => {}
        }
        const productDetails = productstoAdd.map((productToAdd) => {
            const cartProduct = cartItems.find((product) => product.sku === productToAdd.sku)

            if (!cartProduct) return null

            return {
                item_id: cartProduct.sku,
                item_name: cartProduct.genericName,
                item_category: cartProduct.category,
                item_category2: cartProduct.subcategory,
                item_variant: buildVariantForEEC(cartProduct),
                price: cartProduct.price,
                quantity: productToAdd.quantity,
                location_id: dimension
            }
        })
        
        if (typeof window !== 'undefined' && productDetails) {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'add_to_cart',
                ecommerce: {
                    items: productDetails
                }
            })
        }
    } catch (error) {
        console.error(`Error triggering data layer to add item to cart ${error.message}`)
        return () => []
    }
}

export function pushUpdateCartDataLayer(cartId, cartItems) {
    try {
        const products = cartItems.map(item => {
            let discount = 0

            if (item.discounts && item.discounts.length > 0) {
                discount = item.discounts.reduce((a, b) => {
                    return a.amount + b.amount
                }, { amount: 0 })
            }

            return {
                id: item.sku,
                name: item.genericName,
                category: item.category,
                variant: buildVariantForEEC(item),
                brand: 'Saatva Company',
                quantity: item.quantity,
                price: item.price,
                salePrice: item.price - discount,
                variantUrl: item.variantUrl
            }
        })

        window.dataLayer.push({
            'event': 'cartContentsUpdate',
            'cartId': cartId,
            'cartContents': {
                'cartItems': products
            },
            'productImagePaths': cartItems.map((item) => (item.thumbnail)),
            _clear: true // Preventing default recursive merge
        })
    } catch (error) {
        console.error(`Error triggering data layer to add item to cart ${error.message}`)
        return () => []
    }
}
