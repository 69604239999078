import sendTrackingEvent from '@/utils/sendTrackingEvent'
import { getLastUrlSegment } from '@/utils/url'
import { pushDataLayerEvent } from '@/utils/datalayer'

const handleCordialEmailSubmission = async (email, validationHandler) => {
    const subscribeValues = {
        email: email
    }

    let signupBtn = document.activeElement
    let footer = document.querySelector('footer[class*="footer"]') || document.querySelector('footer[data-selector*="main-footer-section"]')
    let isInFooter = footer.contains(signupBtn)

    try {
        if (subscribeValues.email === '') {
            throw new Error('Please submit an email.')
        }

        
        validationHandler && validationHandler('success', 'You\'ve been subscribed!')
        sendTrackingEvent('Email Signup', 'Email Signup Success', 'Subscribed', { metric7: 1 })
        pushDataLayerEvent({
            event: 'emailSignup',
            email: `${email}`,
            signupLocation: (isInFooter ? 'storefrontFooter' : `${getLastUrlSegment()}`)
        })
    } catch (error) {
        console.error(`There was an error creating the Cordial contact with the message: ${error.message}`)
        window?.ineum?.('subscribedUserEmailError', error, { msg: error.message })
        validationHandler('error', error.message)
    }
}

export {
    handleCordialEmailSubmission
}