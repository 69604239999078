import PropTypes from 'prop-types'

const subcategoryItemType = PropTypes.shape({
    imageName: PropTypes.string,
    imageAlt: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    url: PropTypes.string
})

const subcategoryType = PropTypes.shape({
    title: PropTypes.string.isRequired,
    shortTitle: PropTypes.string,
    titleUrl: PropTypes.string,
    subtitle: PropTypes.string,
    items: PropTypes.arrayOf(subcategoryItemType),
    cta: PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    })
})

const linkType = PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    url: PropTypes.string.isRequired,
    className: PropTypes.string
})

const topLevelItemContentType = PropTypes.shape({
    cta: linkType,
    links: PropTypes.arrayOf(linkType).isRequired,
    subcategories: PropTypes.arrayOf(subcategoryType).isRequired
})

const topLevelItemType = PropTypes.shape({
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    url: PropTypes.string.isRequired,
    content: topLevelItemContentType
})

export const navigationDataType = PropTypes.arrayOf(topLevelItemType)
