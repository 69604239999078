export default function sendTrackingEvent(category, action, label, metric = null) {
    return () => {
        try {
            if (metric) {
                window.ga('send', 'event', category, action, label, metric)
            } else {
                window.ga('send', 'event', category, action, label)
            }
        } catch (error) {
            console.error(`There was an error sending the tracking event to GA with the message:${error.message}`)
        }
    }
}
