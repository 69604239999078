import { createContext } from 'react'

export const PagePropsContext = createContext(null)

export const PagePropsProvider = ({ children, pageProps }) => {
    return (
        <PagePropsContext.Provider value={pageProps}>
            {children}
        </PagePropsContext.Provider>
    )
}
