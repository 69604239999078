import { Footer as BitFooter } from '@saatva-bits/pattern-library.modules.footer'
import { handleCordialEmailSubmission } from '@/utils/cordial'
import { open } from '@saatva-bits/pattern-library.utils.chat'

export default function Footer({ footerData, className }) {
    return <BitFooter
        data={footerData}
        emailSubmitHandler={handleCordialEmailSubmission}
        openChat={open}
        className={className}
    />
}
