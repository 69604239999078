import qs from 'qs'

const getQueryParamsObject = () => {
    if (typeof window !== 'undefined') {
        // remove the ? from the string
        const queryString = window.location.search.slice(1)
        return qs.parse(queryString)
    }
    return {}
}

const joinQueryParams = (params, queryName, values, delimiter = ',') => {
    if (values.length > 0) {
        params.set(queryName, values.join(delimiter))
    }
}

const getLastUrlSegment = () => {
    return window.location.pathname.split('/').pop()
}

const getHashParam = () => {
    if (typeof window !== 'undefined') {
        return window.location.hash.split('#')[1]
    }
}

export {
    getQueryParamsObject,
    getLastUrlSegment,
    getHashParam,
    joinQueryParams
}